.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1200px;
    height: auto;
    color: white;
}
.header {
    background: #ba8c2d; /* Cal Poly Green */
    color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem !important;
    min-width: 1200px;
    /* height: 100vh !important; */
    text-align: center;
  }

.title-container {
    width: '100%';
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-menu-title {
    font-family: abolition;
    font-weight: normal;
    letter-spacing: 0.025em;
    font-size: 2.1rem;
    color: #FFFFFF; 
    line-height: 1em;
    margin: 0;
    text-align: center;
    text-indent: 10px;
  }

.header-displayBanner-arrow {
    font-size: 1.75rem;
    color: #FFFFFF; 
}

.dashboard-title{
font-family: sans-serif;
font-weight: regular;
letter-spacing: 0.025em;
font-size: 1.5rem;
color: #FFFFFF;
line-height: 1em;
margin: 0;
text-align: center;
padding-bottom: 10px;
}

.dashboard-content-heading{
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 1.25rem;
    color: #FFFFFF;
    line-height: 1em;
    margin: 0;
    text-align: left;
    padding-left: 10px;
}

.dashboard-content-body{
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 1.15rem;
    color: #FFFFFF;
    line-height: 1em;
    margin: 0;
    text-align: left;
    padding-left: 10px;
    padding-bottom: 7px;
}

.footer-subtitle {
font-family: abolition;
font-weight: normal;
letter-spacing: 0.025em;
font-size: 3rem;
color: #A5D55F; 
line-height: 1em;
text-align: center;
padding-top: 20px;
padding-bottom: 35px;
}

.footer-body {
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 1.2rem;
    color: #FFFFFF; 
    line-height: 1em;
    margin: 0;
    text-align: center;
    padding-bottom: 25px;
}
    
.footer-body-small {
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 1rem;
    color: #FFFFFF; 
    line-height: 1em;
    margin: 0;
    text-align: center;
    padding-bottom: 10px;
}

.footer-body-large {
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 2.1rem;
    color: #FFFFFF; 
    line-height: 1em;
    margin: 0;
    text-align: center;
    padding-bottom: 10px;
}

.display-name {
    /* font-family: Source Sans Pro, sans-serif; */
    word-wrap: break-word;
    font-family: abolition, sans-serif !important;
    font-weight: regular;
    letter-spacing: 3px !important;
    color: #24A0ED;
}
        
.user-details-title {
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 2.2rem;
    color: #FFFFFF;
    line-height: 1em;
    margin: 0;
    text-align: center;
    padding-bottom: 10px;
}

.user-details-info {
    font-family: sans-serif;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 2.2rem;
    color: #c69214;
    line-height: 1em;
    margin: 0;
    text-align: center;
    padding-bottom: 10px;
}

.title-home {
    font-family: sans-serif;
    font-weight: bold;
    letter-spacing: 0.025em;
    font-size: 2.75rem;
    color: #FFFFFF;
    line-height: 1em;
    margin: 0;
    padding-bottom: 3%;
    padding-top: 4%;
}

.home-title {
    font-family: sans-serif;
    font-weight: lighter;
    letter-spacing: 0.025em;
    font-size: 2.rem;
    color: #FFFFFF;
    line-height: 1em;
    padding-left: 2.5%;
    padding-bottom: 1%;
    padding-top: 1%;
}

.home-body {
    font-family: Source Sans Pro, sans-serif !important;
    font-weight: regular;
    letter-spacing: 0.025em;
    font-size: 1rem;
    color: #FFFFFF;
    line-height: 1em;
    padding-bottom: 1em;
    text-align: left;
    padding-left: 2.5%;
    padding-right: 2.5%;
}