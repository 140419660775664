/* General button styling */
button {
  padding: 12px 18px;
  margin: 5px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

/* Hover effect for all buttons */
button:hover {
  background-color: #ddd;
}

/* Selected toggle button styling */
.selected {
  background-color: var(--calpoly-gold);
  color: white;
  font-weight: bold;
}

/* Hover effect for selected buttons */
.selected:hover {
  background-color: var(--calpoly-gold);
}

/* Disabled "Next" button */
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

/* Particles Background */
#particles-js {
  position: fixed;
  /* Ensures it stays fixed in the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  /* Set to a negative z-index so it's behind the modal */
}

/* Modal Container */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
  /* Higher than particles so it's above */
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  z-index: 100;
  /* Highest, so it's above everything */
}

.close {
  color: white;
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 28px;
  cursor: pointer;
  z-index: 100;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: block;
  font-weight: bold;
  margin: 10px 0 5px;
}

input[type="text"],
input[type="email"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
}

/* Confirmation step styling */
pre {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  white-space: pre-wrap;
  font-size: 14px;
  max-height: 400px;
  overflow-y: auto;
}

/* Navigation button container */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

/* Modal Styling */
.review-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.modal-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 100%;
  /* Ensures form doesn't grow beyond modal-content */
  overflow-y: auto;
  /* Allows scrolling inside the form */
  padding-right: 10px;
  /* Prevents content from getting cut off */
}


/* Input Fields */
.input-field {
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

.input-field:focus {
  border-color: var(--calpoly-gold);
  outline: none;
}

/* Banner Container */
.banner {
  background-color: var(--calpoly-gold);
  text-align: center;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* Rounded corners for a softer look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Light shadow for depth */
}

/* Banner Title */
.banner h2 {
  color: #000;
  font-size: 1.5rem;
  /* Slightly larger text */
  margin: 0;
  /* Remove default margin */
  font-family: 'Arial', sans-serif;
  /* Clean font for readability */
  font-weight: bold;
  /* Bold font for emphasis */
}

.error {
  color: red;
  /* Makes the error text red */
  font-size: 14px;
  /* Adjusts the font size */
  font-weight: bold;
  /* Makes the text bold */
  margin-top: 8px;
  /* Adds space above the error message */
  text-align: left;
  /* Aligns text to the left */
}

/* Section Styling */
.section {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}

.section h3 {
  margin-bottom: 10px;
  color: #333;
  text-decoration: underline;
}

/* Checkbox Container */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Coach Info */
.coach-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Student Selection Buttons */
.student-buttons,
.level-buttons {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.student-button,
.level-button {
  padding: 8px 12px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.student-button.selected,
.level-button.selected {
  background-color: var(--calpoly-gold);
  color: white;
  font-weight: bold;
}

.student-button:hover,
.level-button:hover {
  background-color: var(--dark-gold);
  color: white;
}

/* Students Information */
.students-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.student-entry {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Submit Button */
.submit-button {
  background-color: var(--calpoly-gold);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.submit-button:hover {
  background-color: var(--dark-gold);
}

.coachCheck {
  display: flex;
  align-items: center;
  gap: 10px;
}

.coachCheck input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: var(--calpoly-gold);
}